import React from "react";
import { Base_API } from "../../../../_constants/matcher";
import { orderAction } from "../../../../_actions";
import SweetAlert from "react-bootstrap-sweetalert";
import "../order.css";

let modalAlert;

export class Order_Online_Recive_Info extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      messageCreate: "",
      errors: {},
      typeProgram: null,
      selectType: null,
      inputBody: {},
      net_shipping_price: 0,
      inputBodyDetail: [],
    };

  }

  async componentDidMount() {
    this.pageInit();
  }

  async pageInit() {
    var params = this.props.match.params;
    await this.getOrderDataByID(params.od_online_number);
    this.getOrderDetail(params.od_online_number);
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  getOrderDataByID(id) {
    return new Promise(async resolve => {
      orderAction.getOrderOnlineById(id).then(e => {
        if (e.isSuccess === true) {
          var newObject = Object.assign(e.data, this.state.inputBody);
          if(newObject['status_receive'] === "4") newObject['receive_name'] = <strong className="text-warning">{newObject['receive_name']}</strong>
          else if(newObject['status_receive'] === "5") newObject['receive_name'] = <strong className="text-info">{newObject['receive_name']}</strong>
          else if(newObject['status_receive'] === "6") newObject['receive_name'] = <strong className="text-success">{newObject['receive_name']}</strong>
          else if(newObject['status_receive'] === "7") newObject['receive_name'] = <strong className="text-success">{newObject['receive_name']}</strong>
          else if(newObject['status_receive'] === "8") newObject['receive_name'] = <strong className="text-danger">{newObject['receive_name']}</strong>
          else if(newObject['status_receive'] === "10") newObject['receive_name'] = <strong className="text-success">{newObject['status_receive_name']}</strong>
          else if(newObject['status_receive'] === "11") newObject['receive_name'] = <strong className="text-danger">{newObject['status_receive_name']}</strong>
          else newObject['receive_name'] = <strong className="text-info">{newObject['status_receive_name']}</strong>
  
          if(newObject['payment_channel_id'] === 1) newObject['payment_channel_name'] = 'E-Wallet'
          else if(newObject['payment_channel_id'] === 2) newObject['payment_channel_name'] = 'Credit/Debit Card'
          else if(newObject['payment_channel_id'] === 3) newObject['payment_channel_name'] = 'POS'
          else if(newObject['payment_channel_id'] === 4) newObject['payment_channel_name'] = 'ชำระปลายทาง'
          else if(newObject['payment_channel_id'] === 6) newObject['payment_channel_name'] = 'K-PLUS'
          else newObject['payment_channel_name'] = 'ไม่พบช่องทางการชำระเงิน'
  
          if(newObject['driver_phone']){
            let driver_phone = newObject['driver_phone'].replace("66", "0");
            newObject['driver_phone'] = driver_phone
          }
          
          let sum_price = newObject['sum_price']; //ราคารวมสินค้า
          let delivery_cost = newObject['delivery_cost']; //ค่าส่ง
          let discount_delievry_cost = newObject['discount_delievry_cost']; //ส่วนลดค่าส่ง
          let discount_order_cost = newObject['discount_order_cost']; //ส่วนลดคูปอง
  
          let net_shipping_price = (delivery_cost - discount_delievry_cost) > 0 ? (delivery_cost - discount_delievry_cost) : 0
          let net_price = sum_price + net_shipping_price + discount_order_cost
          this.setState({ 
            sum_price: sum_price.toFixed(2),
            delivery_cost: delivery_cost.toFixed(2),
            discount_delievry_cost: discount_delievry_cost.toFixed(2),
            net_shipping_price: net_shipping_price.toFixed(2),
            discount_order_cost: discount_order_cost.toFixed(2),
            net_price: this.numberWithCommas(net_price.toFixed(2)),
            inputBody: newObject 
          });
          resolve();
        } else {
          alert("ไม่พบข้อมูล");
          resolve();
        }
      });
    })
  }

  getOrderDetail(id) {
    orderAction.getOrderDetailId(id).then(e => {
      if (e.isSuccess === true) {
        // cal product price
        let newNetPrice = parseFloat(String(this.state.net_price).replace(/,/g, ''));
        let negPrice = 0;
        if(!!e.data && e.data.length > 0){
          for(var i in e.data){
            let data = e.data[i];
            let index = Number(i);
            let price = parseFloat(data.total_product_price);
            if(price < 0){
              negPrice += Math.abs(price);
            }
            if(index === e.data.length-1){
              newNetPrice = parseFloat(newNetPrice - negPrice).toLocaleString('th', {minimumFractionDigits:2});
            }
          } 
        }
        // put data
        var newObject = Object.assign(e.data, this.state.inputBodyDetail);
        this.setState({ inputBodyDetail: newObject, net_price: newNetPrice });
      } else {
        alert("ไม่พบข้อมูล");
      }
    });
  }

  onImageError(e) {
    e.target.setAttribute(
      "src",
      `${process.env.PUBLIC_URL}/images/default.png`
    );
  }

  render() {
      let listProduct = this.state.inputBodyDetail.map(( item, product ) => {
          return (
            <tr key={product}>
              <td>{item.pr_online_code}</td>
              <td>{item.pr_online_name}</td>
              <td className="text-right">{item.price} ฿</td>
              <td className="text-right">{item.od_online_product_count}</td>
              <td className="text-right">{item.total_product_price} ฿</td>
            </tr>
          );
        })
    return (
      <div className="reward-container row">
        <div className="col-12">
          <div className="panel panel-flat">
            <div className="panel-heading panel-heading-wline">
              <h4 className="panel-title">รายการรับสินค้า (การสั่งซื้อออนไลน์)</h4>
            </div>
            <div className="panel-body pt-4">
              <div className="row">
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12"><strong>ข้อมูลทั่วไป</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">รหัสการซื้อสินค้า :</div>
                    <div className="col-md-8">{this.state.inputBody['od_online_number']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">วันที่สร้างรายการ :</div>
                    <div className="col-md-8">{this.state.inputBody['created_at']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">วันที่ส่งสินค้า :</div>
                    <div className="col-md-8">{this.state.inputBody['date_receive']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-12"><strong>ประเภทการรับสินค้า</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ชื่อประเภทการรับสินค้า :</div>
                    <div className="col-md-8">{this.state.inputBody['receive_channel']}</div>
                  </div>
                  {this.state.inputBody['receive_channel'] === 'gift'
                    && 
                    <div className="row pt-3">
                      <div className="col-md-4">ผู้รับ :</div>
                      <div className="col-md-8">{this.state.inputBody['od_online_gift_phone']}</div>
                    </div>
                  }
                  {this.state.inputBody['receive_channel'] === 'gift'
                    &&
                    <div className="row pt-3">
                      <div className="col-md-4">ข้อความถึงผู้รับ :</div>
                      <div className="col-md-8">{this.state.inputBody['od_online_gift_message']}</div>
                    </div>
                  }
                  <div className="row pt-3">
                      <div className="col-md-4">เวลาที่ต้องการรับสินค้า :</div>
                      <div className="col-md-8">{(this.state.inputBody['receive_channel'] === "pickup" ? this.state.inputBody['time_receive'] : "")}</div>
                    </div>
                  <div className="row pt-3">
                    <div className="col-md-4">หมายเลข tracking :</div>
                    <div className="col-md-8">{(this.state.inputBody['tracking_number'] != null ? this.state.inputBody['tracking_number'] : "")}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-12"><strong>สาขาที่รับสินค้า</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ชื่อสาขา :</div>
                    <div className="col-md-8">{this.state.inputBody['b_name']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ที่อยู่ :</div>
                    <div className="col-md-8">{this.state.inputBody['b_address']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ละติจูด :</div>
                    <div className="col-md-8">{this.state.inputBody['b_lat']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ลองจิจูด :</div>
                    <div className="col-md-8">{this.state.inputBody['b_long']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-12"><strong>ข้อมูลการชำระเงิน</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ช่องทางการชำระเงิน :</div>
                    <div className="col-md-8">{this.state.inputBody['status_payment'] != "1" ? this.state.inputBody['payment_channel_name'] : '-' }</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-12"><strong>ข้อมูลคนขับ</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ชื่ือคนขับ :</div>
                    <div className="col-md-8">{this.state.inputBody['driver_name']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">เบอร์โทรคนขับ :</div>
                    <div className="col-md-8">{this.state.inputBody['driver_phone']}</div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-md-12"><strong>รายละเอียดสมาชิก</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ชื่อสมาชิก :</div>
                    <div className="col-md-8"><a href={`/member/edit/${this.state.inputBody['mem_id']}`}>{this.state.inputBody['mem_firstname']} {this.state.inputBody['mem_lastname']}</a></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">อีเมล :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_email']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">เลขบัตรประชาชน :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_idcard']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">วันเกิด :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_birthdate']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">โทร :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_phone']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-12"><strong>ที่อยู่ (สำหรับจัดส่งสินค้า)</strong></div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">ที่อยู่ :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_address']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">แขวง/ตำบล :</div>
                    <div className="col-md-8">{this.state.inputBody['name_districts']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">เขต/อำเภอ :</div>
                    <div className="col-md-8">{this.state.inputBody['name_amphoe']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">จังหวัด :</div>
                    <div className="col-md-8">{this.state.inputBody['name_province']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">รหัสไปรษณีย์ :</div>
                    <div className="col-md-8">{this.state.inputBody['mem_zipcode']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4"><b>สถานะออเดอร์ :</b></div>
                    <div className="col-md-8">{this.state.inputBody['receive_name']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4"><b>วันที่อัพเดตสถานะออเดอร์ :</b></div>
                    <div className="col-md-8">{this.state.inputBody['updated_at']}</div>
                  </div>
                  <div className="row pt-3">
                    <div className="col-md-4">หมายเหตุ :</div>
                    <div className="col-md-8">{this.state.inputBody['od_online_comment']}</div>
                  </div>
                </div>
              </div>
              <div className="row pt-5 m-1">
                <div className="col-12">
                  <h4 className="panel-title">รายการสั่งซื้อสินค้า</h4>
                </div>
              </div>
              <div className="row pt-3 m-1">
                <div className="col-12">
                  <table className="table table-bordered">
                    <thead>
                      <tr className="text-center">
                        <td><strong>รหัสสินค้า</strong></td>
                        <td width="50%"><strong>ชื่อสินค้า</strong></td>
                        <td><strong>ราคา (/ชิ้น)</strong></td>
                        <td><strong>จำนวน (ชิ้น)</strong></td>
                        <td><strong>ราคารวม</strong></td>
                      </tr>
                    </thead>
                    <tbody>
                      {listProduct}
                      <tr style={{display: this.state.inputBody['rw_transection_id'] ? "" : "none"}}>
                        <td>{(this.state.inputBody['rw_transection_id'])}</td>
                        <td>{(this.state.inputBody['rewards_code'] + " - " + this.state.inputBody['rewards_name_th'])} </td>
                        <td className="text-right">{(this.state.inputBody['discount_order_cost'])} ฿</td>
                        <td className="text-right">{(this.state.inputBody['rw_count'])}</td>
                        <td className="text-right">{(this.state.inputBody['rw_count'] * this.state.inputBody['discount_order_cost']).toFixed(2)} ฿</td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="text-right"><strong>ค่าจัดส่ง</strong></td>
                        <td className="text-right"><strong>{(this.state.delivery_cost)} ฿</strong></td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="text-right"><strong>ส่วนลดค่าจัดส่ง</strong></td>
                        <td className="text-right"><strong>{(this.state.discount_delievry_cost)} ฿</strong></td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="text-right"><strong>ค่าจัดส่งหลังหักค่าส่วนลด</strong></td>
                        <td className="text-right"><strong>{(this.state.net_shipping_price) } ฿</strong></td>
                      </tr>
                      <tr>
                        <td colSpan="4" className="text-right"><strong>รวมเป็นเงิน</strong></td>
                        <td className="text-right"><strong>{(this.state.net_price)} ฿</strong></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {this.state.modal}
      </div>
    );
  }
}
