import React from "react";
import $ from "jquery";
import { Base_API } from "../../../../_constants/matcher";
import { ewalletAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../transaction.css";
import Select from "react-select";
import ReactExport from 'react-data-export';


$.DataTable = require("datatables.net");
let modalAlert;
var dataSearch = {
  searchTransactionID: '-'
}
var multiDataSet

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

let date = new Date()
var dd = date.getDate();
var mm = date.getMonth()+1; 
var yyyy = date.getFullYear();
var hh = date.getHours();
var mn = date.getMinutes();
var ss = date.getSeconds();
var ms = date.getMilliseconds();

if(dd<10) 
{
  dd='0'+dd;
} 

if(mm<10) 
{
  mm='0'+mm;
}

let name_excel = dd.toString()+mm.toString()+yyyy.toString()+hh.toString()+mn.toString()+ss.toString()+ms.toString()+'-transaction-list'


const optionsChannel = [
  { value: '', label: 'All' },
  { value: '1', label: 'E-Wallet' },
  { value: '2', label: 'Cradit/Dabit' },
  { value: '3', label: 'POS' },
];

const optionsType = [
  { value: '', label: 'All' },
  { value: 'T', label: 'Topup' },
  { value: 'W', label: 'Pay' },
  { value: 'V', label: 'Void' },
  { value: 'VT', label: 'Void (Topup)' },
  { value: 'R', label: 'Refund' },
];

export class Transaction_List extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.openModalHandler = this.openModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);

    this.state = {
      show: false,
      modal: null,
      isModal: false,
      fields: {},

    };
  }

  openModalHandler() {
    this.setState({
      isModal: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isModal: false
    });
  };

  componentDidMount() {
    this.showTable(dataSearch);
  }

  showTable(dataSearch){ 
    const columns = [
      {
        title: "Transaction No",
        class: "text-center",
        data: "transaction_id"
      },
      {
        title: "Transaction Number",
        class: "text-center",
        data: "invoice_no"
      },
      {
        title: "Transction Type",
        class: "text-center",
        data: "transaction_type"
      },
      {
        title: "Transaction Description",
        class: "text-center",
        data: "transaction_description"
      },
      {
        title: "Member Number",
        class: "text-center",
        data: "mem_number"
      },
      {
        title: "Member Firstame",
        class: "text-center",
        data: "mem_firstname"
      },
      {
        title: "Member Lastname",
        class: "text-center",
        data: "mem_lastname"
      },
            {
        title: "Phone no.",
        class: "text-center",
        data: "mem_phone"
      },
      {
        title: "Date/Time",
        class: "text-center",
        data: "created_at", 
      },
      {
        title: "Transaction Amount (฿)",
        class: "text-center",
        data: "transaction_amount"
      },
      {
        title: "Manage",
        class: "text-center",
        data: "transaction_id"
      },
    ];
    let table = $(this.refs.main).DataTable({
      columnDefs: [
        {
          targets: 0,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 1,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
              .html(`<div className="text-left">${(cellData ? cellData : '')}</div>`);
          }
        },
        {
          targets: 2,
          createdCell: function(td, cellData, rowData, row, col) {
            var data = '';
            if(cellData == 'T') data = `Topup`
            else if(cellData == 'W') data = `Pay`
            else if(cellData == 'R') data = `Refund`
            else if(cellData == 'V') data = `Void`
            else if(cellData == 'VT') data = `Void (Topup)`
            else data = `-`

            $(td).html(`<div className="text-left">${data}</div>`);
          }
        },
        {
          targets: 3,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 4,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 5,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 6,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 8,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-left">${cellData}</div>`);
          }
        },
        {
          targets: 9,
          createdCell: function(td, cellData, rowData, row, col) {
            $(td)
            .html(`<div className="text-right">${cellData}</div>`);
          }
        },
        {
          targets: 10,
          createdCell: function(td, cellData, rowData, row, col) {
            let disableDeleteBtn = true;
            let transaction_type = String(rowData.transaction_type).toLowerCase();
            if(transaction_type === 't' || transaction_type === 'w') disableDeleteBtn = false;
            var btn_manage = ""
              btn_manage = 
              `<div class="list-icons">
                <div class="dropdown">
                  <a href="#" class="list-icons-item" data-toggle="dropdown">
                    <i class="icon-menu9"></i>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right">
                    <a href="/backoffice/ewallet/refund/create/${rowData.mem_number}/${rowData.transaction_id}" class="dropdown-item"><img src="../../images/icons8-Refund-50.png" class="image-icon mr-3 mb-1"> Refund & Void</a>
                    ${disableDeleteBtn ? `<button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i> Delete </button>` :''}
                  </div>
                </div>
              </div>`
            $(td).html(btn_manage);
            // <button type='button' class="dropdown-item" name='delete_btn' data-content='${cellData}'><i class="icon-trash mr-3 mb-1"></i> Delete </button>
          },
          orderable: false
        }
      ],

      ajax: {
        url: `${Base_API.ewallet}/api/v1/ewallet/search/transactions?searchTransactionID=${dataSearch.searchTransactionID}&searchTransactionType=${dataSearch.searchTransactionType}&searchInvoiceNo=${dataSearch.searchInvoiceNo}&searchMemNumber=${dataSearch.searchMemNumber}&searchCardholderName=${dataSearch.searchCardholderName}&searchPaymentChannelID=${dataSearch.searchPaymentChannelID}&searchTransactionStatus=${dataSearch.searchTransactionStatus}&searchTransactionFrom=${dataSearch.searchTransactionFrom}&searchTransactionTo=${dataSearch.searchTransactionTo}&searchPhoneNo=${dataSearch.searchPhoneNo}`,
        type: "GET",
        dataType: "JSON",
        data: data => {
          return data;
        }
      },
      order: [[8, "DESC"]],
      columns,
      serverSide: true,
      ordering: true,
      searching: false,
      processing: true,
      bLengthChange: false,
      "language": {
        processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading..n.</span> '
      },
    });

    var state = this;

    $(this.refs.main).on("click", 'button[name="delete_btn"]', function() {
      var id = $(this).data("content");
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          info
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title="Delete"
          onConfirm={() => onConfirmDelete(id)}
          onCancel={() => onConfirm()}
        >
          คุณต้องการลบข้อมูลนี้หรือไม่ ?
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    });

    function onConfirmDelete(id) {
      ewalletAction.deleteTransaction(id).then(e => {
        if (e.isSuccess === false) {
          onModalError("Error", e.message);
        } else {
          onModalSuccess("Success", e.message);
          table.ajax.reload();
        }
      });
    }

    function onConfirm(ev) {
      state.setState({ modal: null });
    }

    function onModalSuccess(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }

    function onModalError(head, body) {
      modalAlert = () => (
        <SweetAlert
          style={{ display: "block" }}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={onConfirm}
        >
          {body}
        </SweetAlert>
      );

      state.setState({ show: true, modal: modalAlert() });
    }


  }


  handleSubmit(e) {    
    var table = $('#dataTable').DataTable();
    table.destroy();

    e.preventDefault()
    var dataSearch = {
      searchTransactionID: (this.state.fields["searchTransactionID"] ? this.state.fields["searchTransactionID"] : ''),
      searchMemNumber: (this.state.fields["searchMemNumber"] ? this.state.fields["searchMemNumber"] : ''),
      searchTransactionFrom: (this.state.fields["searchTransactionFrom"] ? this.state.fields["searchTransactionFrom"] : ''),
      searchTransactionTo: (this.state.fields["searchTransactionTo"] ? this.state.fields["searchTransactionTo"] : ''),
      searchCardholderName: (this.state.fields["searchCardholderName"] ? this.state.fields["searchCardholderName"] : ''),
      // searchApprovalCode: (this.state.fields["searchApprovalCode"] ? this.state.fields["searchApprovalCode"] : ''),
      searchPaymentChannelID: (this.state.fields["searchPaymentChannelID"] ? this.state.fields["searchPaymentChannelID"] : ''),
      searchTransactionType: (this.state.fields["searchTransactionType"] ? this.state.fields["searchTransactionType"] : ''),
      searchInvoiceNo: (this.state.fields["searchInvoiceNo"] ? this.state.fields["searchInvoiceNo"] : ''),
      searchPhoneNo: (this.state.fields["searchPhoneNo"] ? this.state.fields["searchPhoneNo"] : '')                                                                     
    }
    this.showTable(dataSearch);
    this.exportExcel(dataSearch)

  }

  handleChange = e => {
    let { fields } = this.state;
    fields[e.target.name] = e.target.value;
    // this.state.errors[e.target.name] = null;
    this.setState({ fields });
  };


  handleSelectChange = (e, action) => {
    let { fields } = this.state;
    fields[action.name] = e.value;
    this.setState({ fields });
  };

  exportExcel = (dataSearch) => {
    ewalletAction.getSearchList(dataSearch)
    .then(res => {
        this.setState({
            dataTransSearch: res.data
        })
    })
  };

  

  render() {

    var dataExcel = []
    let data = this.state.dataTransSearch    
    for(var i in data){
      dataExcel.push(
        [
          {value: data[i].transaction_id},
          {value: (data[i].invoice_no ? data[i].invoice_no : "")},
          {value: data[i].transaction_type},
          {value: (data[i].transaction_description ? data[i].transaction_description : "")},
          {value: (data[i].mem_number ? data[i].mem_number : "")},
          {value: (data[i].mem_firstname ? data[i].mem_firstname : "")},
          {value: (data[i].mem_lastname ? data[i].mem_lastname : "")},
          {value: data[i].mem_phone},
          {value: data[i].created_at},
          {value: data[i].transaction_amount},
          {value: data[i].total_amount},
          {value: (data[i].fees != 'Nan' ? data[i].fees : 0.00)},
          {value: (data[i].net_amount ? data[i].total_amount : 0.00)},
          {value: data[i].transaction_status},
        ],
      )
    }    
    // console.log(dataExcel);
    
    multiDataSet = [
      {
        columns: [
            {title: "Transaction No", width: {wch: 40}},//char width 
            {title: "Transaction Number", width: {wch: 40}},//char width 
            {title: "Transaction Type", width: {wch: 20}},//char width 
            {title: "Transaction Description", width: {wch: 40}},//char width 
            {title: "Member Number", width: {wch: 20}},
            {title: "Member Firstname", width: {wch: 20}},
            {title: "Member Lastname", width: {wch: 20}},
            {title: "Phone no.", width: {wch: 15}},
            {title: "Date/Time", width: {wch: 20}},
            {title: "Transaction Amount", width: {wch: 20}},
            {title: "Total Amount (฿)", width: {wch: 20}},
            {title: "Frees (฿)", width: {wch: 20}},
            {title: "Net Amount (฿)", width: {wch: 20}},
            {title: "Status"},
        ],
        data: dataExcel
      }
    ];

    return (
      <div>
        <ol className="breadcrumb">
          <li>E-Wallet</li>
          <li>Transaction</li>
          <li className="active">Search</li>
        </ol>        
        <ul className="nav nav-tabs">
          <li role="Transactoin" className="active"><a href="#">Transactoin</a></li>
          {/* <li role="History"><a href="/backoffice/ewallet/account/history">History</a></li> */}
        </ul>



        <div className="panel panel-flat panel-ewallet">
          <div className="panel-body">

          <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
            <div>
              <h4>Ewallet Transactions</h4>
              <div className="panel-body panel-color-gray px-5 py-3">
                
                <div className="pl-3">
                  <div className="row my-2">
                    <div className="col-md-3">Transaction No.</div>
                    <div className="col-md-3">
                      <input 
                        type="text" 
                        className="form-control" 
                        name="searchTransactionID"
                        placeholder="" 
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-3">Transaction Number</div>
                    <div className="col-md-3">
                      <input 
                        type="text" 
                        className="form-control" 
                        name="searchInvoiceNo"
                        placeholder="" 
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">Member Number</div>
                    <div className="col-md-3">
                      <input 
                        type="text" 
                        className="form-control" 
                        name="searchMemNumber"
                        placeholder="" 
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md-3">Member Name</div>
                    <div className="col-md-3">
                      <input 
                        type="text" 
                        className="form-control" 
                        name="searchCardholderName"
                        placeholder="" 
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">Phone no.</div>
                    <div className="col-md-3">
                      <input 
                        type="text" 
                        className="form-control" 
                        name="searchPhoneNo"
                        placeholder="" 
                        onChange={this.handleChange}
                        />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">From Date/Time</div>
                    <div className="col-md-3">
                      <input 
                        type="date" 
                        className="form-control" 
                        name="searchTransactionFrom"
                        placeholder="" 
                        onChange={this.handleChange}
                        />
                    </div>
                    <div className="col-md-3">Until Date/Time</div>
                    <div className="col-md-3">
                      <input 
                        type="date" 
                        className="form-control" 
                        name="searchTransactionTo"
                        placeholder="" 
                        onChange={this.handleChange}
                        />
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-md-3">Transaction Channel</div>
                    <div className="col-md-3">
                      <Select
                        className="select-input-month"
                        name="searchPaymentChannelID"
                        placeholder=""
                        onChange={this.handleSelectChange}
                        options={optionsChannel}
                      />
                    </div>

                    <div className="col-md-3">Transaction Type</div>
                    <div className="col-md-3">
                      <Select
                        className="select-input-month"
                        name="searchTransactionType"
                        placeholder=""
                        onChange={this.handleSelectChange}
                        options={optionsType}
                      />
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <div className="">
                    <input type="submit" className="btn btn-success" value="Show Statement"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
            <div>
              <div className="text-right my-3">
                <ExcelFile 
                  element={
                    <button
                    type="button"
                    name="add_banner_btn"
                    data-content=""
                    className="btn btn-add mr-2"
                    >
                    <i className="icon-download"></i> Export Excel
                    </button>
                  }
                  filename = {name_excel}
                >
                  <ExcelSheet dataSet={multiDataSet} name="Transaction-Ewallet" />
                </ExcelFile>
              </div>
            <div>
              
            </div>
              <div className="table-responsive">
                <table id="dataTable" className="table table-hover" ref="main" />
              </div>
            </div>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
