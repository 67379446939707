let backendHost = {};
const urlPath = window.location.hostname
const hostname = window && window.location && window.location.hostname;


if (urlPath === "localhost" || urlPath === "127.0.0.1")
{
  //! UAT
  // backendHost = {
  //   member: 'https://afteryoumobileuat.mmm2007.net:4012',
  //   admin: 'https://afteryoumobileuat.mmm2007.net:4013',
  //   general: 'https://afteryoumobileuat.mmm2007.net:4013',
  //   category: 'https://afteryoumobileuat.mmm2007.net:4014',
  //   product: 'https://afteryoumobileuat.mmm2007.net:4014',
  //   branch: 'https://afteryoumobileuat.mmm2007.net:4016',
  //   branch_preorder: 'https://afteryoumobileuat.mmm2007.net:4016/branch/preorder',
  //   order_online: 'https://afteryoumobileuat.mmm2007.net:4017',
  //   banner: 'https://afteryoumobileuat.mmm2007.net:4017',
  //   news: 'https://afteryoumobileuat.mmm2007.net:4018',
  //   order: 'https://afteryoumobileuat.mmm2007.net:4019',
  //   reward: 'https://afteryoumobileuat.mmm2007.net:4020',
  //   reward_image: 'https://afteryoumobileuat.mmm2007.net:4020/images/',
  //   reward_category_image: 'https://afteryoumobileuat.mmm2007.net:4020/images/',
  //   point: 'https://afteryoumobileuat.mmm2007.net:4021',
  //   queue: 'https://afteryoumobileuat.mmm2007.net:4022',
  //   province: 'https://afteryoumobileuat.mmm2007.net:4023',
  //   upload_img: 'https://afteryoumobileuat.mmm2007.net:4024',
  //   ewallet: 'https://afteryoumobileuat.mmm2007.net:4025',
  //   payment_gateway: 'https://afteryoumobileuat.mmm2007.net:4026',
  //   backoffice: 'https://afteryoumobileuat.mmm2007.net:4027',     
  //   product_v2: 'https://afteryoumobileuat.mmm2007.net:4028',   
  //   pos: 'https://apimobileuat.mmm2007.net'
  // }
  //! Prod
  backendHost = {
    member: 'https://afteryoumobileprod.com:4012',
    admin: 'https://afteryoumobileprod.com:4013',
    general: 'https://afteryoumobileprod.com:4013',
    category: 'https://afteryoumobileprod.com:4014',
    product: 'https://afteryoumobileprod.com:4014',
    branch: 'https://afteryoumobileprod.com:4016',
    branch_preorder: 'https://afteryoumobileprod.com:4016/branch/preorder',
    order_online: 'https://afteryoumobileprod.com:4017',
    banner: 'https://afteryoumobileprod.com:4017',
    news: 'https://afteryoumobileprod.com:4018',
    order: 'https://afteryoumobileprod.com:4019',
    reward: 'https://afteryoumobileprod.com:4020',
    reward_image: 'https://afteryoumobileprod.com:4020/images/',
    reward_category_image: 'https://afteryoumobileprod.com:4020/images/',
    point: 'https://afteryoumobileprod.com:4021',
    queue: 'https://afteryoumobileprod.com:4022',
    province: 'https://afteryoumobileprod.com:4023',
    upload_img: 'https://afteryoumobileprod.com:4024',
    ewallet: 'https://afteryoumobileprod.com:4025',
    payment_gateway: 'https://afteryoumobileprod.com:4026',
    backoffice: 'https://afteryoumobileprod.com:4027',     
    product_v2: 'https://afteryoumobileprod.com:4028',
    pos: 'https://afteryoumobile.com'
  }
  //! PROD
  // backendHost = {
  //   member: 'https://afteryoumobileprod.com:4012',
  //   admin: 'https://afteryoumobileprod.com:4013',
  //   general: 'https://afteryoumobileprod.com:4013',
  //   category: 'https://afteryoumobileprod.com:4014',
  //   product: 'https://afteryoumobileprod.com:4014',
  //   branch: 'https://afteryoumobileprod.com:4016',
  //   branch_preorder: 'https://afteryoumobileprod.com:4016/branch/preorder',
  //   order_online: 'https://afteryoumobileprod.com:4017',
  //   banner: 'https://afteryoumobileprod.com:4017',
  //   news: 'https://afteryoumobileprod.com:4018',
  //   order: 'https://afteryoumobileprod.com:4019',
  //   reward: 'https://afteryoumobileprod.com:4020',
  //   reward_image: 'https://afteryoumobileprod.com:4020/images/',
  //   reward_category_image: 'https://afteryoumobileprod.com:4020/images/',
  //   point: 'https://afteryoumobileprod.com:4021',
  //   queue: 'https://afteryoumobileprod.com:4022',
  //   province: 'https://afteryoumobileprod.com:4023',
  //   upload_img: 'https://afteryoumobileprod.com:4024',
  //   ewallet: 'https://afteryoumobileprod.com:4025',
  //   payment_gateway: 'https://afteryoumobileprod.com:4026',
  //   backoffice: 'https://afteryoumobileprod.com:4027',     
  //   product_v2: 'https://afteryoumobileprod.com:4028',
  //   pos: 'https://afteryoumobile.com'
  // }
}
else if (urlPath === "https://podsable.com" || urlPath === "podsable.com") 
{
  backendHost = {
    member: 'https://podsable.com:4012',
    admin: 'https://podsable.com:4013',
    general: 'https://podsable.com:4013',
    category: 'https://podsable.com:4014',
    product: 'https://podsable.com:4014',
    branch: 'https://podsable.com:4016',
    branch_preorder: 'https://podsable.com:4016/branch/preorder',
    order_online: 'https://podsable.com:4017',
    banner: 'https://podsable.com:4017',
    news: 'https://podsable.com:4018',
    order: 'https://podsable.com:4019',
    reward: 'https://podsable.com:4020',
    reward_image: 'https://podsable.com:4020/images/',
    reward_category_image: 'https://podsable.com:4020/images/',
    point: 'https://podsable.com:4021',
    queue: 'https://podsable.com:4022',
    province: 'https://podsable.com:4023',
    upload_img: 'https://podsable.com:4024',
    ewallet: 'https://podsable.com:4025',
    payment_gateway: 'https://podsable.com:4026',
    backoffice: 'https://podsable.com:4027',     
    product_v2: 'https://podsable.com:4028',
    pos: 'https://apimobileuat.mmm2007.net'
  }
}
else if (urlPath === "https://afteryoumobileprod.com" || urlPath === "afteryoumobileprod.com") 
{
  backendHost = {
    member: 'https://afteryoumobileprod.com:4012',
    admin: 'https://afteryoumobileprod.com:4013',
    general: 'https://afteryoumobileprod.com:4013',
    category: 'https://afteryoumobileprod.com:4014',
    product: 'https://afteryoumobileprod.com:4014',
    branch: 'https://afteryoumobileprod.com:4016',
    branch_preorder: 'https://afteryoumobileprod.com:4016/branch/preorder',
    order_online: 'https://afteryoumobileprod.com:4017',
    banner: 'https://afteryoumobileprod.com:4017',
    news: 'https://afteryoumobileprod.com:4018',
    order: 'https://afteryoumobileprod.com:4019',
    reward: 'https://afteryoumobileprod.com:4020',
    reward_image: 'https://afteryoumobileprod.com:4020/images/',
    reward_category_image: 'https://afteryoumobileprod.com:4020/images/',
    point: 'https://afteryoumobileprod.com:4021',
    queue: 'https://afteryoumobileprod.com:4022',
    province: 'https://afteryoumobileprod.com:4023',
    upload_img: 'https://afteryoumobileprod.com:4024',
    ewallet: 'https://afteryoumobileprod.com:4025',
    payment_gateway: 'https://afteryoumobileprod.com:4026',
    backoffice: 'https://afteryoumobileprod.com:4027',     
    product_v2: 'https://afteryoumobileprod.com:4028',
    pos: 'https://afteryoumobile.com'
  }
}
else if (urlPath === "afteryoumobileuat.com" || urlPath === "afteryoumobileuat.mmm2007.net") 
{
  backendHost = {
    member: 'https://afteryoumobileuat.mmm2007.net:4012',
    admin: 'https://afteryoumobileuat.mmm2007.net:4013',
    general: 'https://afteryoumobileuat.mmm2007.net:4013',
    category: 'https://afteryoumobileuat.mmm2007.net:4014',
    product: 'https://afteryoumobileuat.mmm2007.net:4014',
    branch: 'https://afteryoumobileuat.mmm2007.net:4016',
    branch_preorder: 'https://afteryoumobileuat.mmm2007.net:4016/branch/preorder',
    order_online: 'https://afteryoumobileuat.mmm2007.net:4017',
    banner: 'https://afteryoumobileuat.mmm2007.net:4017',
    news: 'https://afteryoumobileuat.mmm2007.net:4018',
    order: 'https://afteryoumobileuat.mmm2007.net:4019',
    reward: 'https://afteryoumobileuat.mmm2007.net:4020',
    reward_image: 'https://afteryoumobileuat.mmm2007.net:4020/images/',
    reward_category_image: 'https://afteryoumobileuat.mmm2007.net:4020/images/',
    point: 'https://afteryoumobileuat.mmm2007.net:4021',
    queue: 'https://afteryoumobileuat.mmm2007.net:4022',
    province: 'https://afteryoumobileuat.mmm2007.net:4023',
    upload_img: 'https://afteryoumobileuat.mmm2007.net:4024',
    ewallet: 'https://afteryoumobileuat.mmm2007.net:4025',
    payment_gateway: 'https://afteryoumobileuat.mmm2007.net:4026',
    backoffice: 'https://afteryoumobileuat.mmm2007.net:4027',     
    product_v2: 'https://afteryoumobileuat.mmm2007.net:4028',
    pos: 'https://apimobileuat.mmm2007.net'
  }
}
  
  //! Prod
  // backendHost = {
  //   member: 'https://afteryoumobileprod.com:4012',
  //   admin: 'https://afteryoumobileprod.com:4013',
  //   general: 'https://afteryoumobileprod.com:4013',
  //   category: 'https://afteryoumobileprod.com:4014',
  //   product: 'https://afteryoumobileprod.com:4014',
  //   branch: 'https://afteryoumobileprod.com:4016',
  //   branch_preorder: 'https://afteryoumobileprod.com:4016/branch/preorder',
  //   order_online: 'https://afteryoumobileprod.com:4017',
  //   banner: 'https://afteryoumobileprod.com:4017',
  //   news: 'https://afteryoumobileprod.com:4018',
  //   order: 'https://afteryoumobileprod.com:4019',
  //   reward: 'https://afteryoumobileprod.com:4020',
  //   reward_image: 'https://afteryoumobileprod.com:4020/images/',
  //   reward_category_image: 'https://afteryoumobileprod.com:4020/images/',
  //   point: 'https://afteryoumobileprod.com:4021',
  //   queue: 'https://afteryoumobileprod.com:4022',
  //   province: 'https://afteryoumobileprod.com:4023',
  //   upload_img: 'https://afteryoumobileprod.com:4024',
  //   ewallet: 'https://afteryoumobileprod.com:4025',
  //   payment_gateway: 'https://afteryoumobileprod.com:4026',
  //   backoffice: 'https://afteryoumobileprod.com:4027',     
  // }

// if (urlPath === "https://afteryou-backoffice.podsable.com" || urlPath === "afteryou-backoffice.podsable.com") {
//       backendHost = {
//         member: 'https://podsable.com:4012',
//         admin: 'https://podsable.com:4013',
//         general: 'https://podsable.com:4013',
//         category: 'https://podsable.com:4014',
//         product: 'https://podsable.com:4014',
//         branch: 'https://podsable.com:4016',
//         branch_preorder: 'https://podsable.com:4016/branch/preorder',
//         order_online: 'https://podsable.com:4017',
//         banner: 'https://podsable.com:4017',
//         news: 'https://podsable.com:4018',
//         order: 'https://podsable.com:4019',
//         reward: 'https://podsable.com:4020',
//         reward_image: 'https://podsable.com:4020/images/',
//         reward_category_image: 'https://podsable.com:4020/images/',
//         point: 'https://podsable.com:4021',
//         queue: 'https://podsable.com:4022',
//         province: 'https://podsable.com:4023',
//         upload_img: 'https://podsable.com:4024',
//         ewallet: 'https://podsable.com:4025',
//         payment_gateway: 'https://podsable.com:4026',
//         backoffice: 'https://podsable.com:4027',     
//       }
// } else if (urlPath === "localhost") {
//     backendHost = {
//       category: 'http://localhost:4014',
//       product: 'http://localhost:4014',
//       branch: 'http://localhost:4016',
//       branch_preorder: 'http://localhost:4016/branch/preorder',
//       reward: 'http://localhost:4020',
//       reward_image: 'http://localhost:4020/images/',
//       reward_category_image: 'http://localhost:4020/images/',
//       member: 'http://localhost:4012',
//       order: 'http://localhost:4019',
//       news: 'http://localhost:4018',
//       admin: 'http://localhost:4013',
//       queue: 'http://localhost:4022',
//       order_online: 'http://localhost:4017',
//       province: 'http://localhost:4023',
//       upload_img: 'http://localhost:4024',
//       point: 'http://localhost:4021',
//       banner: 'http://localhost:4017',
//       ewallet: 'http://localhost:4025',
//       general: 'http://localhost:4013',
//       payment_gateway: 'http://localhost:4026',

//     }
// } else if (urlPath === "grabapi.afteryoudessertcafe.com" || urlPath === "https://grabapi.afteryoudessertcafe.com") {
//     backendHost = {
//       category: 'https://grabapi.afteryoudessertcafe.com:4014',
//       product: 'https://grabapi.afteryoudessertcafe.com:4014',
//       branch: 'https://grabapi.afteryoudessertcafe.com:4016',
//       branch_preorder: 'https://grabapi.afteryoudessertcafe.com:4016/branch/preorder',
//       reward: 'https://grabapi.afteryoudessertcafe.com:4020',
//       reward_image: 'https://grabapi.afteryoudessertcafe.com:4020/images/',
//       reward_category_image: 'https://grabapi.afteryoudessertcafe.com:4020/images/',
//       member: 'https://grabapi.afteryoudessertcafe.com:4012',
//       order: 'https://grabapi.afteryoudessertcafe.com:4019',
//       news: 'https://grabapi.afteryoudessertcafe.com:4018',
//       admin: 'https://grabapi.afteryoudessertcafe.com:4013',
//       queue: 'https://grabapi.afteryoudessertcafe.com:4022',
//       order_online: 'https://grabapi.afteryoudessertcafe.com:4017',
//       province: 'https://grabapi.afteryoudessertcafe.com:4023',
//       upload_img: 'https://grabapi.afteryoudessertcafe.com:4024',
//       point: 'https://grabapi.afteryoudessertcafe.com:4021',
//       banner: 'https://grabapi.afteryoudessertcafe.com:4017',
//       ewallet: 'https://grabapi.afteryoudessertcafe.com:4025',
//       general: 'https://grabapi.afteryoudessertcafe.com:4013',
//       payment_gateway: 'https://grabapi.afteryoudessertcafe.com:4026',
//       backoffice: 'https://grabapi.afteryoudessertcafe.com:4027',     
//     }
// } else if (urlPath === "https://podsable.com" || urlPath === "podsable.com") 
// {
//   backendHost = {
//     member: 'https://podsable.com:4012',
//     admin: 'https://podsable.com:4013',
//     general: 'https://podsable.com:4013',
//     category: 'https://podsable.com:4014',
//     product: 'https://podsable.com:4014',
//     branch: 'https://podsable.com:4016',
//     branch_preorder: 'https://podsable.com:4016/branch/preorder',
//     order_online: 'https://podsable.com:4017',
//     banner: 'https://podsable.com:4017',
//     news: 'https://podsable.com:4018',
//     order: 'https://podsable.com:4019',
//     reward: 'https://podsable.com:4020',
//     reward_image: 'https://podsable.com:4020/images/',
//     reward_category_image: 'https://podsable.com:4020/images/',
//     point: 'https://podsable.com:4021',
//     queue: 'https://podsable.com:4022',
//     province: 'https://podsable.com:4023',
//     upload_img: 'https://podsable.com:4024',
//     ewallet: 'https://podsable.com:4025',
//     payment_gateway: 'https://podsable.com:4026',
//     backoffice: 'https://podsable.com:4027',     
//   }
// } else {
//     backendHost = {
//       category: 'https://grabapi.afteryoudessertcafe.com:4014',
//       product: 'https://grabapi.afteryoudessertcafe.com:4014',
//       branch: 'https://grabapi.afteryoudessertcafe.com:4016',
//       branch_preorder: 'https://grabapi.afteryoudessertcafe.com:4016/branch/preorder',
//       reward: 'https://grabapi.afteryoudessertcafe.com:4020',
//       reward_image: 'https://grabapi.afteryoudessertcafe.com:4020/images/',
//       reward_category_image: 'https://grabapi.afteryoudessertcafe.com:4020/images/',
//       member: 'https://grabapi.afteryoudessertcafe.com:4012',
//       order: 'https://grabapi.afteryoudessertcafe.com:4019',
//       news: 'https://grabapi.afteryoudessertcafe.com:4018',
//       admin: 'https://grabapi.afteryoudessertcafe.com:4013',
//       queue: 'https://grabapi.afteryoudessertcafe.com:4022',
//       order_online: 'https://grabapi.afteryoudessertcafe.com:4017',
//       province: 'https://grabapi.afteryoudessertcafe.com:4023',
//       upload_img: 'https://grabapi.afteryoudessertcafe.com:4024',
//       point: 'https://grabapi.afteryoudessertcafe.com:4021',
//       banner: 'https://grabapi.afteryoudessertcafe.com:4017',
//       ewallet: 'https://grabapi.afteryoudessertcafe.com:4025',
//       general: 'https://grabapi.afteryoudessertcafe.com:4013',
//       payment_gateway: 'https://grabapi.afteryoudessertcafe.com:4026',
//       backoffice: 'https://grabapi.afteryoudessertcafe.com:4027',     
//     }
// }

//     backendHost = {
//       category: 'https://grabapi.afteryoudessertcafe.com:4014',
//       product: 'https://grabapi.afteryoudessertcafe.com:4014',
//       branch: 'https://grabapi.afteryoudessertcafe.com:4016',
//       branch_preorder: 'https://grabapi.afteryoudessertcafe.com:4016/branch/preorder',
//       reward: 'https://grabapi.afteryoudessertcafe.com:4020',
//       reward_image: 'https://grabapi.afteryoudessertcafe.com:4020/images/',
//       reward_category_image: 'https://grabapi.afteryoudessertcafe.com:4020/images/',
//       member: 'https://grabapi.afteryoudessertcafe.com:4012',
//       order: 'https://grabapi.afteryoudessertcafe.com:4019',
//       news: 'https://grabapi.afteryoudessertcafe.com:4018',
//       admin: 'https://grabapi.afteryoudessertcafe.com:4013',
//       queue: 'https://grabapi.afteryoudessertcafe.com:4022',
//       order_online: 'https://grabapi.afteryoudessertcafe.com:4017',
//       province: 'https://grabapi.afteryoudessertcafe.com:4023',
//       upload_img: 'https://grabapi.afteryoudessertcafe.com:4024',
//       point: 'https://grabapi.afteryoudessertcafe.com:4021',
//       banner: 'https://grabapi.afteryoudessertcafe.com:4017',
//       ewallet: 'https://grabapi.afteryoudessertcafe.com:4025',
//       general: 'https://grabapi.afteryoudessertcafe.com:4013',
//       payment_gateway: 'https://grabapi.afteryoudessertcafe.com:4026',
//       backoffice: 'https://grabapi.afteryoudessertcafe.com:4027',     
//     }

export const Base_API = backendHost;
