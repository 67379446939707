import React from "react";
import { ewalletAction } from '../../../../_actions'
import SweetAlert from 'react-bootstrap-sweetalert';
import "../refund.css";
import Select from "react-select";

let modalAlert;

export class Refund_Create extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      show: false,
      modal: null,
      modalOption: {},
      errors: {},
      fields: {
        settlement_Initiated_by: 'backoffice',
        payment_channel_id: 5,
        approval_code: ''
      },
      disableRefundRadio: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
    this.onConfirmCreate = this.onConfirmCreate.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    this.setState({
      transaction_id: params.transaction_id,
      mem_number: params.mem_number
    })

    this.getDetailTransaction(params.transaction_id)
  }

  onModalSuccess(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          success
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onConfirm}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalWarning(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          warning
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalError(head, body) {
    modalAlert = () => (
      <SweetAlert
          style={{display:'block'}}
          error
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          title={head}
          onConfirm={this.onCancel}
          >
          {body}
          </SweetAlert>
      );

      this.setState({ show:true, modal: modalAlert() })
  }

  onModalQuestion(head, body) {
    modalAlert = () => (
    <SweetAlert
        style={{display:'block'}}
        info
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title={head}
        onConfirm={() => this.onConfirmCreate()}
        onCancel={() => this.onCancel()}
        >
        {body}
        </SweetAlert>
    );

    this.setState({ show:true, modal: modalAlert() })
  }


  handleSubmit(event) {
    event.preventDefault()
    this.setState({ modalOption: null })

    if (this.validateForm()) {
      this.onModalQuestion('Confirm','คุณต้องการบันทึกข้อมูล ?')
    }
  }


  onConfirm () {
    this.setState({ modal: null })
    window.location.href="/backoffice/ewallet/account/overview"
  }

  validateForm() {

    let fields = this.state.fields;
    let chk = this.state.chk;
    let errors = {};
    let formIsValid = true;

    if (!fields["chk_type"]) {
      formIsValid = false;
      errors["chk_type"] = "*กรุณากรอกวิธีการคืนเงิน";
    }
    if (!fields["transaction_amount"]) {
      formIsValid = false;
      errors["transaction_amount"] = "*กรุณากรอกจำนวนเงิน";
    }
    console.log('fields: ', fields);
    if (parseFloat(fields["transaction_amount"]) > parseFloat(fields['total_amount'])) {
      formIsValid = false;
      errors["transaction_amount"] = "*กรุณากรอกจำนวนเงินที่ต้องการ refund น้อยกว่าจำนวนเงินที่ชำระ";
    }

    this.setState({
      errors: errors
    });
    return formIsValid;
  }

  onConfirmCreate(ev) {
    this.setState({ modal: null })
    let transaction_amount = this.state.fields["transaction_amount"]
    transaction_amount=transaction_amount.replace(/\,/g,''); // 1125, but a string, so convert it to number
    transaction_amount=parseFloat(transaction_amount);

    
    var inputBody = ({
      "transaction_type" : (this.state.fields['transaction_type'] == 'T' ? 'VT' : this.state.fields["chk_type"]),
      "transaction_id_ref" : this.state.transaction_id,
      'transaction_amount' : transaction_amount,
      'payment_channel_id' : this.state.fields["payment_channel_id"],
      'mem_number' : this.state.fields["mem_number"],
      'approval_code' : this.state.fields["approval_code"],
      'transaction_comment' : this.state.fields["transaction_comment"],
      'transaction_description' : ( this.state.fields['transaction_type'] == 'T' && this.state.fields["chk_type"] == "V" 
            ? "Void Topup via Backoffice" 
            : (this.state.fields['transaction_type'] != 'T' && this.state.fields["chk_type"] == "V" 
              ? "Void via Backoffice" 
              : "Refund via Backoffice")),
      'settlement_Initiated_by': this.state.fields['settlement_Initiated_by']

    });    
    ewalletAction.createRefund(inputBody).then(e => {
      if(e.isSuccess === true) {
        this.onModalSuccess('Success', e.message)
      } else {
        this.onModalError('Error', e.message)
      }
    })
  }

  onCancel(ev) {
    this.setState({ modal: null });
  }

  handleChange = e => {
    let { readOnly, fields, errors } = this.state;
    fields[e.target.name] = e.target.value;

    if(fields['chk_type'] == "V"){
      fields["transaction_amount"] = fields["total_amount"];
      readOnly = true
    }else if(fields['chk_type'] == "R"){
      // fields["transaction_amount"] = ""
      readOnly = false
    }
    this.state.errors[e.target.name] = null;
    this.setState({ readOnly, fields, errors });
  };

  getDetailTransaction(transaction_id) {    
    let { fields, disableRefundRadio } = this.state;

    ewalletAction.getDetailTransaction(transaction_id).then(res => {
      let transaction_type = String(res.data.transaction_type).toLowerCase();
      fields['transaction_type'] = res.data.transaction_type
      fields['transaction_description'] = res.data.transaction_description
      fields['total_amount'] = String(res.data.total_amount).replace(/,/g, '');
      fields['transaction_amount'] = String(res.data.transaction_amount).replace(/,/g, '');
      fields['settlement_date'] = res.data.settlement_date
      fields['invoice_no'] = res.data.invoice_no
      fields['cardholder_name'] = res.data.cardholder_name
      if(transaction_type === 't'){
        disableRefundRadio = true;
      }
      this.setState({ fields, disableRefundRadio });
    })
  }


  render() {
    return (
      <div>
        <div className="panel panel-flat">
          <div className="panel-heading panel-heading-wline">
            <h4 className="panel-title">Refund & Void E-Wallet</h4>
          </div>
          <div className="panel-body">
            <form className="form-horizontal" onSubmit={(e) => this.handleSubmit(e)}>
              <fieldset className="content-group">
                <div className="form-group">
                  <label className="control-label col-md-2">Transaction Number</label>
                  <label className="control-label col-md-3">{this.state.transaction_id}</label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">Transaction Type</label>
                  <label className="control-label col-md-3">{`(` + this.state.fields.transaction_type + `) ` + this.state.fields.transaction_description}</label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">Order Number</label>
                  <label className="control-label col-md-3">{this.state.fields.invoice_no}</label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">Transaction Amonut (฿)</label>
                  <label className="control-label col-md-3">{this.state.fields.total_amount}</label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">Member Number</label>
                  <label className="control-label col-md-3">{this.state.mem_number}</label>
                </div>
                <div className="form-group">
                  <label className="control-label col-md-2">Member Name</label>
                  <label className="control-label col-md-3">{this.state.fields.cardholder_name}</label>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">Type</label>
                  <div className="col-md-5">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="chk_type" id="chk_refund" value="R" onChange={this.handleChange} disabled={this.state.disableRefundRadio}/>
                      <label className="form-check-label" htmlFor="chk_refund"> &nbsp;&nbsp; Refund</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="chk_type" id="chk_void" value="V" onChange={this.handleChange} />
                      <label className="form-check-label" htmlFor="chk_void"> &nbsp;&nbsp; Void</label>
                    </div>
                    <div className="errorMsg">{this.state.errors.chk_type}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">Amount</label>
                  <div className="col-md-5">
                  <input
                      name="transaction_amount"
                      type="text" 
                      // pattern="[0-9]*"
                      // step="any"
                      className="form-control"
                      placeholder=""
                      value={this.state.fields["transaction_amount"] || ""}
                      onChange={this.handleChange}
                      readOnly={this.state.readOnly}
                    />
                    <div className="errorMsg">{this.state.errors.transaction_amount}</div>
                  </div>
                </div>

                <div className="form-group">
                  <label className="control-label col-md-2">Comment</label>
                  <div className="col-md-5">
                    <textarea
                      // rows="3"
                      name="transaction_comment"
                      className="form-control"
                      onChange={this.handleChange}
                    />
                    <div className="errorMsg">{this.state.errors.transaction_comment}</div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="col-lg-12 text-right">
                    <input
                      type="submit"
                      className="btn btn-success btn-md btn-banner-create"
                      value="บันทึก"
                    />
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
        {this.state.modal}
      </div>
    );
  }
}
